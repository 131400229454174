<template>
    <div v-loading.fullscreen.lock="loading">
        <contract-search-bar-component
            add_auth="新增合同"
            :AllEnterprise="AllEnterprise"
            :AllClient="AllClient"
            :AllUrgentState="AllUrgentState"
            :AllBusinessType="AllBusinessType"
            :AllContractType="AllContractType"
            :AllSubjectNum="AllSubjectNum"
            :daoqi_num="daoqi_num"
            :guoqi_num="guoqi_num"
            @search="get_contract_index"
            @addTeam="addTeam"
            @out_excel="outExcel"
        ></contract-search-bar-component>
        <common-table-component
            details_auth="合同详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_contract"
            table_height="600px"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_contract_index"
        ></common-page-component>
        <contract-edit-component
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :contract_details_data="contract_details_data"
            :edit_auth="edit_auth"
            :del_auth="del_auth"
            :add_file_auth="add_file_auth"
            :to_approval_auth="to_approval_auth"
            :sel_approval_auth="sel_approval_auth"
            :ApprovalAuth="ApprovalAuth"
            :withdraw_auth="withdraw_auth"
            :record_add_auth="record_add_auth"
            :record_details_auth="record_details_auth"
            :record_edit_auth="record_edit_auth"
            :record_del_auth="record_del_auth"
            :cancel_auth="cancel_auth"
            print_auth="打印合同"
            add_auth="新增合同"
            :AllEnterprise="AllEnterprise"
            :AllUrgentState="AllUrgentState"
            :AllBusinessType="AllBusinessType"
            :AllContractType="AllContractType"
            :AllSubjectNum="AllSubjectNum"
            :AllSettlementMode="AllSettlementMode"
            :AllSettlementDayType="AllSettlementDayType"
            :AllSettlementCycle="AllSettlementCycle"
            @show_edit="show_edit"
            @addTeam="addTeam"
            @search="get_contract_index"
            @exitContractDialog="dialogExit"
            @frefresh="frefresh"
            @details_row="details_contract"
        ></contract-edit-component>
        <!-- 导出 -->
        <common-out-put-excel-component
            ref="out_excel"
            :TableName="out_excel_name"
            :HeaderArr="out_excel_heard"
            :BobyArr="out_excel_data"
        ></common-out-put-excel-component>
    </div>
</template>

<script>
import ContractSearchBarComponent from '@/components/finance/Contract/ContractSearchBarComponent.vue'
import { contract_index_request,contract_details_request,out_excel_request} from '@/network/finance/Contract.js'
import { enterprise_list_request,client_list_request } from '@/network/list.js'

import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import ContractEditComponent from '@/components/finance/Contract/ContractEditComponent'

import CommonOutPutExcelComponent from '@/components/common/CommonOutPutExcelComponent'

export default {
    name:'ContractView',
    data(){
        return {
            fid: 0,
            edit_type: 0,
            loading: false,
            fdialogFormVisible: false,
            cond: {
                name: '',
                state: '',
                record_state:'',
                urgent_state:'',
                subject_num: '',
                contract_type:'',
                business_type:'',
                staff_id: '',
                create_staff: '',
                enterprise_id: '',
                client_name: '',
                start_month: '',
                end_month: '',
                end_date: '',
                daoqi:false,
                guoqi:false,
                page: 1,
                limit: 10,
            },
            ftable_data :[],
            ftotal:0,
            daoqi_total:0,
            guoqi_total:0,
            contract_details_data:{},
            fcol_arr:[
                {
                    prop: 'name',
                    label: '合同单号',
                    minWidth: '120px'
                },
                {
                    prop: 'current_examine_staff_name',
                    label: '当前审批人',
                    minWidth: '100px'
                },
                // {
                //     prop: 'urgent_state',
                //     label: '紧急状态',
                //     minWidth: '100px',
                //     tag: (d) => {
                //         if(d===0) {
                //             return {
                //                 type: 'info',
                //                 filter_data: '一般'
                //             }
                //         } else if(d===1) {
                //             return {
                //                 type: 'warning',
                //                 filter_data: '紧急'
                //             }
                //         }else if(d===2) {
                //             return {
                //                 type: 'danger',
                //                 filter_data: '特急'
                //             }
                //         }
                //     }
                // },
                {
                    prop: 'state',
                    label: '审批状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待提交'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已通过'
                            }
                        } else if(d===3) {
                            return {
                                type: 'danger',
                                filter_data: '已驳回'
                            }
                        }
                    }
                },
                {
                    prop: 'record_state',
                    label: '备案状态',
                    minWidth: '100px',
                    html: (row) => {
                        if(row['record_state']===0) {
                            return `<div class="cell"><span class="el-tag el-tag--info el-tag--plain">未备案</span></div>`
                        } else {
                            return `<div class="cell"><span class="el-tag el-tag--success el-tag--plain">已备案</span></div>`
                        }
                    }
                },
                {
                    prop: 'perform_state',
                    label: '履行状态',
                    minWidth: '100px',
                    html: (row) => {
                        if(row['perform_state']===0) {
                            return `<div class="cell"><span class="el-tag el-tag--info el-tag--plain">待定</span></div>`
                        } else if(row['perform_state']===1) {
                            return `<div class="cell"><span class="el-tag el-tag--success el-tag--plain">正常</span></div>`
                        } else if(row['perform_state']===2) {
                            return `<div class="cell"><span class="el-tag el-tag--warning el-tag--plain">过期</span></div>`
                        } else if(row['perform_state']===3) {
                            return `<div class="cell"><span class="el-tag el-tag--danger el-tag--plain">否决</span></div>`
                        } else if(row['perform_state']===4) {
                            return `<div class="cell"><span class="el-tag el-tag--danger el-tag--plain">作废</span></div>`
                        }
                    }
                },
                {
                    prop: 'enterprise_name',
                    label: '我方企业',
                    minWidth: '260px',
                    show_overflow_tooltip: true,
                },
                {
                    prop: 'company_a_name',
                    label: '合同主体',
                    minWidth: '260px',
                    show_overflow_tooltip: true,
                    html: (row) => {
                        let client_name = [row['company_a_name'],row['company_b_name']]
                        if(row['company_c_name']) {
                            client_name.push(row['company_c_name'])
                        }
                        if(row['company_d_name']) {
                            client_name.push(row['company_d_name'])
                        }
                        return '<div>' + client_name.join('</div><div>') + '</div>'
                    }
                },
                {
                    prop: 'contract_type',
                    label: '合同类型',
                    minWidth: '100px',
                    editdata: (row) => {
                        return this.AllContractType[row['contract_type']]
                    }
                },
                {
                    prop: 'business_type',
                    label: '业务类型',
                    minWidth: '120px',
                    editdata: (row) => {
                        return this.AllBusinessType[row['business_type']]
                    }
                },
                {
                    prop: 'title',
                    label: '标题',
                    minWidth: '300px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'start_date',
                    label: '开始时间',
                    minWidth: '120px'
                },
                {
                    prop: 'end_date',
                    label: '结束时间',
                    minWidth: '120px'
                },
                {
                    prop: 'create_staff_name',
                    label: '创建人',
                    minWidth: '100px',
                }
            ],
            AllEnterprise: [],
            AllClient: [],
            AllUrgentState: ['一般','紧急','特急'],
            AllBusinessType: ['服务','装卸','仓库租赁','仓储保管','公路运输','航空运输','报关报检','多式联运','码头装卸(理货)','水路运输','铁路运输'],
            AllContractType: ['采购','销售'],
            AllSubjectNum: ['双方协议','三方协议','四方协议'],
            AllSettlementMode: ['收到发票后','货物签收后','报关后','货物到港后','驳船开后','海船开后','货物到港前','次月','订舱后','货物出运前','本月','合同生效后'],
            AllSettlementDayType: ['自然日','工作日'],
            AllSettlementCycle: ['付款买单','票结','月结','预付款','年结'],
            edit_auth: false,
            del_auth: false,
            //补充附件
            add_file_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false,
            //新增合同备案
            record_add_auth: false,
            //合同备案详情
            record_details_auth: false,
            //修改合同备案
            record_edit_auth: false, 
            //删除合同备案
            record_del_auth: false,
            //合同作废
            cancel_auth: false,
            out_excel_heard: [],
            out_excel_data: []
        }
    },
    computed:{
        daoqi_num() {
            return this.daoqi_total === 0 ? '' : this.daoqi_total
        },
        guoqi_num() {
            return this.guoqi_total === 0 ? '' : this.guoqi_total
        },
        out_excel_name() {
            const n = new Date();
            const year = n.getFullYear();
            const month = (((n.getMonth()) + 1)+'').length === 1 ? ('0' + ((n.getMonth()) + 1)) : ((n.getMonth()) + 1);
            const date = (n.getDate()+'').length === 1 ? ('0' + n.getDate()) : n.getDate();
            const hour = (n.getHours()+'').length === 1 ? ('0' + n.getHours()) : n.getHours();
            const minute = (n.getMinutes()+'').length === 1 ? ('0' + n.getMinutes()) : n.getMinutes();
            const second = (n.getSeconds()+'').length === 1 ? ('0' + n.getSeconds()) : n.getSeconds();

            return '合同' + year +  month + date +  hour +  minute + second
        }
    },
    methods:{
        get_contract_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.state = param.state ?? this.cond.state
            this.cond.record_state = param.record_state ?? this.cond.record_state
            this.cond.urgent_state = param.urgent_state ?? this.cond.urgent_state
            this.cond.perform_state = param.perform_state ?? this.cond.perform_state
            this.cond.subject_num = param.subject_num?? this.cond.subject_num
            this.cond.contract_type = param.contract_type?? this.cond.contract_type
            this.cond.business_type = param.business_type ?? this.cond.business_type
            this.cond.staff_id = param.staff_id ?? this.cond.staff_id
            this.cond.create_staff = param.create_staff ?? this.cond.create_staff
            this.cond.enterprise_id = param.enterprise_id ?? this.cond.enterprise_id
            this.cond.client_name = param.client_name ?? this.cond.client_name
            this.cond.start_month = param.start_month ?? this.cond.start_month
            this.cond.end_month = param.end_month ?? this.cond.end_month
            this.cond.end_date = param.end_date ?? this.cond.end_date
            this.cond.daoqi = param.daoqi ?? this.cond.daoqi
            this.cond.guoqi = param.guoqi ?? this.cond.guoqi
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            contract_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                        this.daoqi_total = s.result.count_daoqi
                        this.guoqi_total = s.result.count_guoqi
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_contract(id){
            this.fid = id
            this.fdialogFormVisible = true
            contract_details_request(id)
                .then((s) => {                    
                    if (s.status === 0) {
                        this.contract_details_data = s.result                        
                        //权限判断
                        this.is_auth(s.result)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        is_auth(res) {
            this.edit_auth = false
            this.del_auth = false
            this.add_file_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false
            this.record_add_auth = false
            this.record_details_auth = false
            this.record_edit_auth = false
            this.record_del_auth = false
            this.cancel_auth = false
            
            if(res.state === 0) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交合同')
                    this.edit_auth = this.$_has('修改合同')
                    this.del_auth = this.$_has('删除合同')
                }
            } else if(res.state === 1) {
                 //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回合同')
                    this.add_file_auth = this.$_has('补充合同附件')
                }
                //判断当前审批人是否为自己
                if(res.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批合同')
                }
                this.sel_approval_auth = this.$_has('查看合同审批记录')
            } else if(res.state === 2) {
                this.sel_approval_auth = this.$_has('查看合同审批记录')
                this.cancel_auth = this.$_has('合同作废')
                
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.add_file_auth = this.$_has('补充合同附件')
                }
            } else if(res.state === 3) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交合同')
                    this.edit_auth = this.$_has('修改合同')
                    this.del_auth = this.$_has('删除合同')
                }
                this.sel_approval_auth = this.$_has('查看合同审批记录')
            }

            if(res.record_state === 0) {
                this.record_add_auth = this.$_has('新增合同备案')
            } else {
                this.record_details_auth = this.$_has('合同备案详情')
                this.record_edit_auth = this.$_has('修改合同备案')
                this.record_del_auth = this.$_has('删除合同备案')
            }
        },
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        frefresh() {
            this.details_contract(this.fid)
            this.get_contract_index()
        },
        get_enterprise_list() {
            enterprise_list_request({})
                .then((s) => {
                    this.AllEnterprise = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_client_list() {
            client_list_request({})
                .then((s) => {
                    this.AllClient = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        outExcel() {
            this.out_excel_heard = [
                {label: '单号',prop: 'name'},
                {label: '审批状态',prop: 'state_name'},
                {label: '备案状态',prop: 'record_state_name'},
                {label: '履行状态',prop: 'perform_state_name'},
                {label: '我方企业',prop: 'enterprise_name'},
                {label: '负责人',prop: 'staff_name'},
                {label: '合同类型',prop: 'contract_type_name'},
                {label: '业务类型',prop: 'business_type_name'},
                {label: '合同标题',prop: 'title'},
                {label: '甲方企业',prop: 'company_a_name'},
                {label: '乙方企业',prop: 'company_b_name'},
                {label: '丙方企业',prop: 'company_c_name'},
                {label: '丁方企业',prop: 'company_d_name'},
                {label: '合同开始日期',prop: 'start_date'},
                {label: '合同结束日期',prop: 'end_date'},
                {label: '创建人',prop: 'create_staff_name'},
                {label: '商务备注',prop: 'yangmei_note'}
            ]

            out_excel_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.out_excel_data = s.result
                        this.$refs['out_excel'].outExcel()
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_contract_index()
        this.get_enterprise_list()
        this.get_client_list()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        ContractSearchBarComponent,
        CommonPageComponent,
        CommonTableComponent,
        ContractEditComponent,
        CommonOutPutExcelComponent
    },
    watch:{}
}
</script>

<style>
</style>